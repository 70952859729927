<template>
  <div>
    <b-card no-body>
      <b-card-body>
        <b-row>
          <!--        <b-row class="justify-content-between">-->
          <b-col cols="3">
            <b-form-group
              label="사이트"
              label-for="branch-site"
            >
              <b-form-select
                v-if="authSite"
                id="branch-site"
                ref="branch-site"
                v-model="selectedSite"
                :options="authSite"
                class="font-small-3"
                style="color:rgb(229,166,48);"
              />
            </b-form-group>
          </b-col>
          <b-col
            cols="3"
          >
            <b-form-group
              label="총판"
              label-for="branch"
            >
              <b-form-select
                id="branch"
                ref="branch"
                v-model="branchSelected"
                :options="branchSelectOptions"
                class="font-small-3"
                style="color:rgb(229,166,48)"
              />
            </b-form-group>
          </b-col>
          <b-col
            cols="6"
          >
            <b-input-group
              class="calendar-size font-small-3"
              style="padding-top: 1.5rem"
            >
              <b-form-datepicker
                v-model="periodFrom"
                class="align-self-center"
                boundary="window"
                locale="ko"
                placeholder="시작일"
                aria-controls="example-input"
                size="sm"
                :max="periodTo"
              />
              <div class="pl-1 pr-1">
                ~
              </div>
              <b-form-datepicker
                v-model="periodTo"
                class="align-self-center"
                boundary="window"
                locale="ko"
                placeholder="종료일"
                aria-controls="example-input"
                size="sm"
                :min="periodFrom"
              />
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                size="sm"
                class="ml-1"
                @click="searchData"
              >
                검색
              </b-button>
            </b-input-group>
          </b-col>
          <!--        </b-row>-->
          <!--        <b-row class="justify-content-between">-->
          <b-col
            cols="12"
            md="12"
          >
            <b-form-group
              label="검색"
              label-for="branch-input"
              class="font-small-3"
            >
              <b-input-group size="sm">
                <b-form-input
                  id="filterInput"
                  v-model="searchFilter"
                  type="search"
                  placeholder="검색: 상위/추천, 닉네임/이름, 아이디, 전화번호 ..."
                  style="color: #99dff1"
                />
                <b-input-group-append>
                  <b-button
                    :disabled="!searchFilter"
                    @click="searchFilter = ''"
                  >
                    Clear
                  </b-button>
                </b-input-group-append>
              </b-input-group>
            </b-form-group>
          </b-col>
        </b-row>
      </b-card-body>
    </b-card>
    <b-card
      no-body
      class="mb-0"
    >
      <div>
        <div class="d-flex justify-content-between">
          <div>
            <div
              class="text-info small pt-1"
              style="padding-left: 2rem"
            >
              Z: 이월되는 Z`의 값
            </div>
            <div
              class="text-info small"
              style="padding-left: 2rem"
            >
              Z`: 총판포함 하위캐쉬 + 총판포함 하위카지노캐쉬 + 총판포함 하위포인트
            </div>
          </div>
          <div>
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="outline-info"
              class="btn-icon mt-1"
              style="margin-right: 1rem"
              @click="btnModal('withdrawHistory')"
            >
              총판 출금내역
            </b-button>
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              :variant="isBranchWithrawable ? 'outline-info' : 'outline-secondary'"
              class="btn-icon mt-1"
              style="margin-right: 2rem"
              @click="btnModal('withdraw')"
            >
              총판 출금신청
            </b-button>
            <b-modal
              ref="ModalBranchCashOut"
              no-close-on-backdrop
              hide-footer
              centered
              size="sm"
            >
              <!-- Modal Header -->
              <template #modal-header>
                <h5 class="modal-title">
                  총판출금신청
                </h5>
                <div class="modal-actions">
                  <feather-icon
                    icon="XIcon"
                    class="ml-1 cursor-pointer"
                    @click="$refs.ModalBranchCashOut.hide()"
                  />
                </div>
              </template>
              <b-form @submit.prevent>
                <b-row>
                  <b-col>
                    <b-form-group
                      class="font-small-3"
                      label="총판명"
                      label-for="main-notice"
                      label-cols-md="4"
                    >
                      <div
                        class="font-small-3"
                        size="sm"
                        style="color:rgb(229,166,48); margin-top: 6px"
                      >
                        {{ cashOutBranchName }}
                      </div>
                    </b-form-group>
                    <b-form-group
                      class="font-small-3"
                      label="정산 적용일자"
                      label-for="main-notice"
                      label-cols-md="4"
                    >
                      <div
                        class="font-small-3"
                        size="sm"
                        style="color:rgb(229,166,48); margin-top: 6px"
                      >
                        {{ cashOutBranchDate }}
                      </div>
                    </b-form-group>
                    <b-form-group
                      class="font-small-3"
                      label="출금 요청중인 금액"
                      label-for="main-notice"
                      label-cols-md="4"
                    >
                      <div
                        class="font-small-3"
                        size="sm"
                        style="color:rgb(229,166,48); margin-top: 6px"
                      >
                        {{ formatNumberWithCommas(cashOutBranchRequested) }}
                      </div>
                    </b-form-group>
                    <b-form-group
                      class="font-small-3"
                      label="출금 가능한 금액"
                      label-for="main-notice"
                      label-cols-md="4"
                    >
                      <div
                        class="font-small-3"
                        size="sm"
                        style="color:rgb(229,166,48); margin-top: 6px"
                      >
                        {{ formatNumberWithCommas(cashOutBranchAvailable) }}
                      </div>
                    </b-form-group>
                    <b-form-group
                      class="font-small-3"
                      label="출금 요청할 금액"
                      label-for="main-notice"
                      label-cols-md="4"
                    >
                      <b-form-input
                        id="main-notice"
                        v-model="cashOutBranchAmount"
                        class="font-small-3 text-info"
                        size="sm"
                        placeholder="출금요청할 금액을 입력하세요"
                        style="color:rgb(229,166,48)"
                        type="number"
                        :class="{ 'is-valid': cashOutBranchAmountValidateState }"
                      />
                      <b-form-invalid-feedback :state="cashOutBranchAmountValidateState">
                        {{ cashOutBranchAmountValidateMessage }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                    <b-form-group
                      class="font-small-3"
                      label="출금 적용 후 금액"
                      label-for="main-notice"
                      label-cols-md="4"
                    >
                      <div
                        class="font-small-3"
                        size="sm"
                        style="color:rgb(229,166,48); margin-top: 6px"
                      >
                        {{ formatNumberWithCommas((cashOutBranchAvailable) - cashOutBranchAmount) || 0 }}
                      </div>
                    </b-form-group>
                    <b-form-group
                      class="font-small-3"
                      label="요청 내용"
                      label-for="main-notice"
                      label-cols-md="4"
                    >
                      <b-form-textarea
                        id="main-notice"
                        v-model="cashOutBranchComment"
                        class="font-small-3 text-info"
                        size="sm"
                        placeholder="특이사항이 있을경우 입력하세요"
                        rows="2"
                      />
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row class="py-1">
                  <b-col>
                    <b-button
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      variant="primary"
                      size="sm"
                      class="float-right"
                      :disabled="!cashOutBranchAmountValidateState"
                      @click="btnConfirm('BranchCashOut')"
                    >
                      출금신청
                    </b-button>
                    <b-button
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      size="sm"
                      class="float-right mr-1"
                      @click="btnModal('close')"
                    >
                      취소
                    </b-button>
                  </b-col>
                </b-row>
              </b-form>
            </b-modal>
          </div>
        </div>
        <b-row class="m-1">
          <b-col>
            <b-table
              ref="branchDailyTable"
              responsive
              small
              show-empty
              empty-text="조회내역이 없습니다."
              thead-class="text-center"
              tbody-class="text-center"
              sort-by="baseDate"
              :sort-desc="false"
              :items="branchDailyItems"
              :fields="branchDailyFields"
              :tbody-transition-props="transProps"
              :tbody-tr-attr="rowStyle"
              class="main-table"
            >
              <template #thead-top>
                <b-tr>
                  <b-th
                    rowspan="3"
                    class="align-middle"
                    style="font-size: small; min-width: 7rem;"
                  >
                    일자
                  </b-th>
                  <b-th
                    rowspan="3"
                    class="align-middle"
                    style="font-size: small; min-width: 10rem;"
                  >
                    총판명
                  </b-th>
                  <b-th
                    colspan="2"
                    class="align-middle"
                    style="font-size: small; "
                  >
                    현재머니
                  </b-th>
                  <b-th
                    colspan="2"
                    class="text-center align-middle"
                  >
                    입출금
                  </b-th>
                  <b-th
                    rowspan="3"
                    class="align-middle"
                    style="font-size: small; min-width: 5rem; background-color: #21315a;"
                  >
                    충-환<br>
                    <span style="font-size: xx-small;">(B=X-Y)</span>
                  </b-th>
                  <b-th
                    colspan="6"
                    class="text-center align-middle"
                  >
                    롤링
                  </b-th>
                  <b-th
                    rowspan="3"
                    class="align-middle"
                    style="font-size: small; min-width: 5rem; background-color: #21315a;"
                  >
                    롤링금 합계<br>
                    <span style="font-size: xx-small;">(C=W+Y+Z)</span>
                  </b-th>
                  <b-th
                    colspan="3"
                    class="text-center align-middle"
                  >
                    루징
                  </b-th>
                  <b-th
                    rowspan="3"
                    class="text-center align-middle"
                    style="background-color: #21315a;"
                  >
                    루징금<br>합계<br>
                    <span style="font-size: xx-small;"> (F=D+E) </span>
                  </b-th>
                  <b-th
                    rowspan="3"
                    class="align-middle"
                    style="font-size: small; min-width: 5rem;"
                  >
                    총판<br> 누적 루징금<br>
                    <span style="font-size: xx-small;"> (G=F+F`) </span>
                  </b-th>
                  <b-th
                    rowspan="3"
                    class="align-middle"
                    style="font-size: small; min-width: 5rem;"
                  >
                    총판<br>출금액<br>
                    <span style="font-size: xx-small;"> (H) </span>
                  </b-th>
                  <b-th
                    rowspan="3"
                    class="align-middle"
                    style="font-size: small; min-width: 5rem;"
                  >
                    총판<br>출금가능<br>
                    <span style="font-size: xx-small;"> (I=G-H) </span>
                  </b-th>
                </b-tr>
                <b-tr>
                  <b-th
                    rowspan="2"
                    class="text-center align-middle"
                    style="font-size: small; "
                  >
                    캐쉬
                  </b-th>
                  <b-th
                    rowspan="2"
                    class="text-center align-middle"
                    style="font-size: small; "
                  >
                    포인트
                  </b-th>
                  <b-th
                    rowspan="2"
                    class="text-center align-middle"
                    style="font-size: small; "
                  >
                    충전<br>
                    <span style="font-size: xx-small;">(M)</span>
                  </b-th>
                  <b-th
                    rowspan="2"
                    class="text-center align-middle"
                    style="font-size: small; "
                  >
                    환전<br>
                    <span style="font-size: xx-small;">(N)</span>
                  </b-th>
                  <b-th
                    colspan="2"
                    class="text-center align-middle"
                  >
                    스포츠
                  </b-th>
                  <b-th
                    colspan="2"
                    class="text-center align-middle"
                  >
                    카지노
                  </b-th>
                  <b-th
                    colspan="2"
                    class="text-center align-middle"
                  >
                    슬롯
                  </b-th>
                  <b-th
                    colspan="3"
                    class="text-center align-middle"
                  >
                    <span style="font-size: xx-small;">D,E=(B-A-C)*R</span>
                  </b-th>
                </b-tr>
                <b-tr>
                  <b-th
                    class="text-center align-middle"
                    style="font-size: small; "
                  >
                    배팅금
                  </b-th>
                  <b-th
                    class="text-center align-middle"
                    style="font-size: small; "
                  >
                    롤링금
                    <span style="font-size: xx-small;">(W)</span>
                  </b-th>
                  <b-th
                    class="text-center align-middle"
                    style="font-size: small; "
                  >
                    배팅금
                  </b-th>
                  <b-th
                    class="text-center align-middle"
                    style="font-size: small; "
                  >
                    롤링금
                    <span style="font-size: xx-small;">(X)</span>
                  </b-th>
                  <b-th
                    class="text-center align-middle"
                    style="font-size: small; "
                  >
                    배팅금
                  </b-th>
                  <b-th
                    class="text-center align-middle"
                    style="font-size: small; "
                  >
                    롤링금
                    <span style="font-size: xx-small;">(Y)</span>
                  </b-th>

                  <b-th
                    class="text-center align-middle"
                    style="font-size: small; "
                  >
                    요율
                    <span style="font-size: xx-small;">(R)</span>
                  </b-th>
                  <b-th
                    class="text-center align-middle"
                    style="font-size: small; "
                  >
                    자신
                    <span style="font-size: xx-small;">(D)</span>
                  </b-th>
                  <b-th
                    class="text-center align-middle"
                    style="font-size: small; "
                  >
                    하위
                    <span style="font-size: xx-small;">(E)</span>
                  </b-th>
                </b-tr>
              </template>
              <template #cell(curCash)="data">
                <div
                  v-b-tooltip.hover.bottom.html="'캐쉬: ' + Number(data.item.curCash).toLocaleString() + ' (+' + Number(data.item.curLowerCash).toLocaleString() + ')'"
                  class="text-right"
                  style="font-size: small; min-width: 6rem;"
                >
                  {{ Number(data.item.curCash + data.item.curLowerCash).toLocaleString() }}
                </div>
              </template>
              <template #cell(curPoint)="data">
                <div
                  v-b-tooltip.hover.bottom.html="'포인트: ' + Number(data.item.curPoint).toLocaleString() + ' (+' + Number(data.item.curLowerPoint).toLocaleString() + ')'"
                  class="text-right"
                  style="font-size: small; min-width: 6rem;"
                >
                  {{ Number(data.item.curPoint + data.item.curLowerPoint).toLocaleString() }}
                </div>
              </template>

              <template #cell(deposit)="data">
                <div
                  v-b-tooltip.hover.bottom.html="Number(data.item.deposit).toLocaleString() + '(+' + Number(data.item.lowerDeposit).toLocaleString() + ')'"
                  class="text-right"
                  style="font-size: small; min-width: 6rem;"
                >
                  {{ Number(data.item.deposit + data.item.lowerDeposit).toLocaleString() }}
                </div>
              </template>
              <template #cell(exchange)="data">
                <div
                  v-b-tooltip.hover.bottom.html="Number(data.item.exchange).toLocaleString() + '(+' + Number(data.item.lowerExchange).toLocaleString() + ')'"
                  class="text-right"
                  style="font-size: small; min-width: 6rem;"
                >
                  {{ Number(data.item.exchange + data.item.lowerExchange).toLocaleString() }}
                </div>
              </template>
              <template
                #cell(balance)="data"
              >
                <div
                  class="text-right"
                  style="font-size: small; min-width: 6rem; "
                >
                  <!-- :style="{
                    fontSize: 'small',
                    minWidth: '6rem',
                    textDecoration: getTotalBalance(data).isActive ? 'underline' : 'none',
                    textUnderlineOffset: getTotalBalance(data).isActive ? '5px' : 'initial'
                  }"
                  @click="getTotalBalance(data).isActive && fncFetchBalanceDepositExchange(data)"
                   -->
                  {{ getTotalBalance(data).total.toLocaleString() }}
                </div>
              </template>

              <template #cell(rolling)="data">
                <div
                  class="text-right"
                  :style="{
                    fontSize: 'small',
                    minWidth: '6rem',
                    textDecoration: getTotalRollingAmount(data) > 0 ? 'underline' : 'none',
                    textUnderlineOffset: getTotalRollingAmount(data) > 0 ? '5px' : 'initial'
                  }"
                  @click="getTotalRollingAmount(data) > 0 && fncFetchBalanceRolling(data)"
                >
                  <!-- {{ Number(data.item.totalSportRollingAmount + data.item.totalCasinoRollingAmount + data.item.totalSlotRollingAmount ).toLocaleString() }} -->
                  {{ getTotalRollingAmount(data).toLocaleString() }}
                </div>
              </template>
              <template #cell(type)="data">
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  class="btn-icon"
                  size="sm"
                  :variant="data.item.type === 'ok' ? 'secondary' : 'primary'"
                  :disabled="data.item.type === 'ok'"
                  @click="updateStatusCommission(data.item)"
                >
                  <feather-icon
                    :icon="data.item.type === 'ok' ? 'CheckIcon' : 'DollarSignIcon'"
                    size="14"
                  />
                </b-button>
              </template>
              <template
                v-if="branchDailyItems.length > 0"
                slot="bottom-row"
              >
                <td
                  colspan="2"
                  style="background-color: #24233a; color:rgb(196,186,138)"
                >
                  Total
                </td>
                <td
                  style="background-color: #24233a; color:rgb(196,186,138)"
                />
                <td
                  style="background-color: #24233a; color:rgb(196,186,138)"
                />
                <td
                  class="text-right"
                  style="background-color: #24233a; color:rgb(196,186,138)"
                >
                  {{ Number(getTotal('deposit')).toLocaleString() }}
                </td>
                <td
                  class="text-right"
                  style="background-color: #24233a; color:rgb(196,186,138)"
                >
                  {{ Number(getTotal('exchange')).toLocaleString() }}
                </td>
                <td
                  class="text-right"
                  style="background-color: #24233a; color:rgb(196,186,138)"
                >
                  {{ Number(getTotal('exchange')-getTotal('deposit')).toLocaleString() }}
                </td>
                <td
                  class="text-right"
                  style="background-color: #24233a; color:rgb(196,186,138)"
                >
                  {{ Number(getTotal('totalSportBettingAmount')).toLocaleString() }}
                </td>
                <td
                  class="text-right"
                  style="background-color: #24233a; color:rgb(196,186,138)"
                >
                  {{ Number(getTotal('totalSportRollingAmount')).toLocaleString() }}
                </td>
                <td
                  class="text-right"
                  style="background-color: #24233a; color:rgb(196,186,138)"
                >
                  {{ Number(getTotal('totalCasinoBettingAmount')).toLocaleString() }}
                </td>
                <td
                  class="text-right"
                  style="background-color: #24233a; color:rgb(196,186,138)"
                >
                  {{ Number(getTotal('totalCasinoRollingAmount')).toLocaleString() }}
                </td>
                <td
                  class="text-right"
                  style="background-color: #24233a; color:rgb(196,186,138)"
                >
                  {{ Number(getTotal('totalSlotBettingAmount')).toLocaleString() }}
                </td>
                <td
                  class="text-right"
                  style="background-color: #24233a; color:rgb(196,186,138)"
                >
                  {{ Number(getTotal('totalSlotRollingAmount')).toLocaleString() }}
                </td>
                <td
                  class="text-right"
                  style="background-color: #24233a; color:rgb(196,186,138)"
                >
                  {{ Number(getTotal('totalSportRollingAmount') + getTotal('totalCasinoRollingAmount') + getTotal('totalSlotRollingAmount')).toLocaleString() }}
                </td>
                <td
                  style="background-color: #24233a; color:rgb(196,186,138)"
                />
                <td
                  class="text-right"
                  style="background-color: #24233a; color:rgb(196,186,138)"
                >
                  {{ Number(getTotal('profit')).toLocaleString() }}
                </td>
                <td
                  class="text-right"
                  style="background-color: #24233a; color:rgb(196,186,138)"
                >
                  {{ Number(getTotal('accLowerProfit')).toLocaleString() }}
                </td>
                <td
                  class="text-right"
                  style="background-color: #24233a; color:rgb(196,186,138)"
                >
                  {{ Number(getTotal('sumProfit')).toLocaleString() }}
                </td>
                <td
                  class="text-right"
                  style="background-color: #24233a; color:rgb(196,186,138)"
                >
                  {{ Number(getTotal('accProfit')).toLocaleString() }}
                </td>
                <td
                  class="text-right"
                  style="background-color: #24233a; color:rgb(196,186,138)"
                >
                  {{ Number(getTotal('withdrawnAmount')).toLocaleString() }}
                </td>
                <td
                  class="text-right"
                  style="background-color: #24233a; color:rgb(196,186,138)"
                >
                  {{ Math.abs(Number(getTotal('withdrawableAmount'))).toLocaleString() }}
                </td>
              </template>
            </b-table>
          </b-col>
        </b-row>
      </div>
      <branch-cash-out-balance
        ref="BranchCashOutBalance"
        :site="selectedSite"
      />
      <branch-cash-out-request
        ref="BranchCashOutRequest"
      />
      <branch-balance-rolling
        ref="BranchBalanceRolling"
        :fetch-balance-rolling="fetchBalanceRolling"
      />
    </b-card>
    <hr>
  </div>
</template>

<script>
import {
  BCard,
  BCardBody,
  BTable,
  BTr,
  BTh,
  BButton,
  BRow,
  BCol,
  BForm,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BFormValidFeedback,
  BPagination,
  BFormSelect,
  BInputGroupAppend,
  BInputGroup,
  BBadge,
  BFormDatepicker,
  BCardText,
  BFormTextarea,
  VBTooltip,
  VBModal,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import Swal from 'sweetalert2'
import vSelect from 'vue-select'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

import { createNamespacedHelpers } from 'vuex'
import { FETCH_BRANCH_SELECT_OPTIONS, FETCH_BRANCH_WITHDRAWABLE_DAY, FETCH_BALANCE_BRANCH_DAILY_PROFIT, FETCH_BALANCE_ROLLING } from '@/store/branch/action'
import { CREATE_BRANCH_CASHOUT_REQUEST } from '@/store/branch/mutation'
import { FETCH_REQUEST_ALARMS_BRANCH_CASHOUT } from '@/store/notice/action'
import { UPSERT_REQUEST_ALARMS } from '@/store/settings/mutation'

import BranchCashOutBalance from '@/views/branch/BranchCashOutBalance.vue'
import BranchCashOutRequest from '@/views/branch/BranchCashOutRequest.vue'
import BranchBalanceRolling from '@/views/branch/BranchCalculateDailyBalanceRolling.vue'
import * as moment from 'moment-timezone'

moment().tz('Asia/Seoul')
const fmt1 = 'YYYY-MM-DD HH:mm'
const fmt2 = 'YYYYMMDD'
const fmt3 = 'YYYY-MM-DD'

const branchStore = createNamespacedHelpers('branchStore')
const noticeStore = createNamespacedHelpers('noticeStore')
const settingsStore = createNamespacedHelpers('settingsStore')

export default {
  name: 'BranchCalculateDaily',

  components: {
    BCard,
    BCardBody,
    BTable,
    BTr,
    BTh,
    BButton,
    BRow,
    BCol,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BFormValidFeedback,
    BPagination,
    BFormSelect,
    BInputGroupAppend,
    BInputGroup,
    BBadge,
    BFormDatepicker,
    vSelect,
    BCardText,
    BFormTextarea,

    BranchCashOutBalance,
    BranchCashOutRequest,
    BranchBalanceRolling,
  },
  directives: {
    Ripple,
    'b-tooltip': VBTooltip,
    'b-modal': VBModal,
  },
  data() {
    return {
      currentURL: window.location.href,
      componentName: this.$options.name,

      userData: JSON.parse(localStorage.getItem('userData')),
      userIp: localStorage.getItem('ip'),
      authSite: localStorage.getItem('authSite').split(','),
      selectedSite: localStorage.getItem('authSiteSelected'),
      branchSelected: null,

      searchFilter: null,
      filteredBranchList: [],

      isBranchWithrawable: false,
      msgBranchWithrawable: '',
      cashOutBranchName: JSON.parse(localStorage.getItem('userData')).nickname,
      cashOutBranchDate: moment(new Date()).subtract(1, 'days').format(fmt3),
      // cashOutBranchRequested: 0,
      // cashOutBranchAvailable: 0,
      cashOutBranchAmount: '',
      cashOutBranchComment: '',

      cashOutBranchAmountValidateState: null,
      cashOutBranchAmountValidateMessage: '',

      branchDailyItems: [],
      branchDailyFields: [
        { key: 'baseDate', thClass: 'd-none', formatter: 'getDateWithDash' },
        { key: 'branch', thClass: 'd-none' },
        { key: 'curCash', thClass: 'd-none', tdClass: 'text-right', formatter: 'formatNumberWithCommas' },
        { key: 'curPoint', thClass: 'd-none', tdClass: 'text-right', formatter: 'formatNumberWithCommas' },
        { key: 'deposit', thClass: 'd-none', tdClass: 'text-right', formatter: 'formatNumberWithCommas' },
        { key: 'exchange', thClass: 'd-none', tdClass: 'text-right', formatter: 'formatNumberWithCommas' },
        { key: 'balance', thClass: 'd-none', tdClass: 'text-right', formatter: 'formatNumberWithCommas' },
        { key: 'totalSportBettingAmount', thClass: 'd-none', tdClass: 'text-right', formatter: 'formatNumberWithCommas' },
        { key: 'totalSportRollingAmount', thClass: 'd-none', tdClass: 'text-right', formatter: 'formatNumberWithCommas' },
        { key: 'totalCasinoBettingAmount', thClass: 'd-none', tdClass: 'text-right', formatter: 'formatNumberWithCommas' },
        { key: 'totalCasinoRollingAmount', thClass: 'd-none', tdClass: 'text-right', formatter: 'formatNumberWithCommas' },
        { key: 'totalSlotBettingAmount', thClass: 'd-none', tdClass: 'text-right', formatter: 'formatNumberWithCommas' },
        { key: 'totalSlotRollingAmount', thClass: 'd-none', tdClass: 'text-right', formatter: 'formatNumberWithCommas' },
        { key: 'rolling', thClass: 'd-none', tdClass: 'text-right', formatter: 'formatNumberWithCommas' },
        { key: 'rate', thClass: 'd-none' },
        { key: 'profit', thClass: 'd-none', tdClass: 'text-right', formatter: 'formatNumberWithCommas' },
        { key: 'accLowerProfit', thClass: 'd-none', tdClass: 'text-right', formatter: 'formatNumberWithCommas' },
        { key: 'sumProfit', thClass: 'd-none', tdClass: 'text-right', formatter: 'formatNumberWithCommas' }, // 루징금합계
        { key: 'accProfit', thClass: 'd-none', tdClass: 'text-right', formatter: 'formatNumberWithCommas' }, // 총판 누적 루징금
        { key: 'withdrawnAmount', thClass: 'd-none', tdClass: 'text-right', formatter: 'formatNumberWithCommas' },
        { key: 'withdrawableAmount', thClass: 'd-none', tdClass: 'text-right', formatter: 'formatNumberWithCommas' },
      ],
      periodFrom: '',
      periodTo: '',

      transProps: {
        name: 'fade',
        mode: 'in-out',
      },
    }
  },
  computed: {
    ...branchStore.mapGetters({
      fetchBranchSelectOptions: 'fetchBranchSelectOptions',
      fetchBranchWithdrawableDay: 'fetchBranchWithdrawableDay',
      fetchBalanceBranchDailyProfit: 'fetchBalanceBranchDailyProfit',
      fetchBalanceRolling: 'fetchBalanceRolling',
    }),
    ...noticeStore.mapGetters({
      fetchRequestAlarmsBranchCashout: 'fetchRequestAlarmsBranchCashout',
    }),
    branchSelectOptions() {
      const resultData = this.fetchBranchSelectOptions.map(option => ({
        value: option,
        text: option.status === 'active'
          ? `[${option.role}] ${option.nickname}`
          : `[${option.role}] ${option.nickname} (${option.status})`,
        selected: option.userid === this.userData.userid,
      }))
      this.branchSelected = this.fetchBranchSelectOptions.find(option => option.userid === this.userData.userid)
      return resultData
    },
    // 총판 출금 신청금액
    cashOutBranchRequested() {
      return this.fetchRequestAlarmsBranchCashout.reduce((sum, item) => ((item.requestNickname === this.branchSelected.branch && item.confirmed === 'REQUEST') ? sum + item.requestCash : sum), 0)
    },
    // 총판 출금 가능금액
    cashOutBranchAvailable() {
      const baseDate = moment(new Date()).subtract(1, 'days').format(fmt2) // 기준일자
      // console.log(JSON.stringify(this.branchDailyItems))
      // console.log(baseDate, this.branchSelected.branch, this.branchDailyItems.find(item => item.branch === this.branchSelected.branch && item.baseDate === baseDate))
      // const branchCashBalance = this.branchDailyItems.find(item => item.branch === this.branchSelected.branch && item.baseDate === baseDate)?.branchCashBalance 임시

      const branchCashBalance = this.branchDailyItems.find(item => item.branch === this.branchSelected.branch && item.baseDate === baseDate)?.totalProfitRunning
      // console.log('총판 출금 가능금액', branchCashBalance)
      // console.log('총판 출금 신청금액', this.cashOutBranchRequested)
      return branchCashBalance - this.cashOutBranchRequested || 0
    },
  },
  watch: {
    selectedSite: {
      handler(event) {
        localStorage.setItem('authSiteSelected', event || localStorage.getItem('authSiteSelected'))
        this.$store.commit('footerStore/setSelectedSiteIndex', this.authSite.indexOf(event))
        this.init()
      },
      immediate: false,
      deep: false,
    },

    branchSelected: {
      handler() {
        this.fetchData()
      },
      immediate: false,
      deep: false,
    },
    searchFilter() {
      // console.log(this.fetchRequestAlarmsBranchCashout)

      if (this.searchFilter || this.searchFilter === '') {
        const data = this.fetchBalanceBranchDailyProfit.filter(branch => Object.values(branch).some(val => val && val.toString().toLowerCase().includes(this.searchFilter.toLowerCase())))
        this.branchDailyItems = data
      }
    },
    cashOutBranchAmount(newVal, oldVal) {
      // console.log('newVal :', newVal)
      // console.log('oldVal :', oldVal)
      // if (newVal > this.cashOutBranchAvailable) {
      //   this.$nextTick(() => {
      //     this.cashOutBranchAmount = oldVal // 이전 값으로 되돌리기
      //   })
      // }
      this.fncCashOutBranchAmountValidate(newVal, oldVal)
    },
  },
  created() {
    // console.log(this.componentName, 'created()')
    this.init()
  },
  mounted() {
    // console.log(this.componentName, 'mounted()')
  },
  methods: {
    ...branchStore.mapActions({
      $fetchBranchSelectOptions: FETCH_BRANCH_SELECT_OPTIONS,
      $fetchBranchWithdrawableDay: FETCH_BRANCH_WITHDRAWABLE_DAY,
      $fetchBalanceBranchDailyProfit: FETCH_BALANCE_BRANCH_DAILY_PROFIT,
      $fetchBalanceRolling: FETCH_BALANCE_ROLLING,
      $createBranchCashoutRequest: CREATE_BRANCH_CASHOUT_REQUEST,
    }),
    ...noticeStore.mapActions({
      $fetchRequestAlarmsBranchCashout: FETCH_REQUEST_ALARMS_BRANCH_CASHOUT,
    }),
    ...settingsStore.mapActions({
      $upsertRequestAlarms: UPSERT_REQUEST_ALARMS,
    }),
    async init() {
      // this.periodFrom = `${new Date().toISOString().slice(0, 8)}01`
      // this.periodTo = new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0).toISOString().slice(0, 10)
      this.periodFrom = moment(new Date()).startOf('month').format(fmt1)
      this.periodTo = moment(new Date()).endOf('month').format(fmt1)

      await this.$fetchBranchSelectOptions({
        site: this.selectedSite,
        userid: this.userData.userid,
      })
      await this.fetchData()
      this.isActiveBranchWithrawable() /* 출금신청 버튼 활성화 로직 */
    },
    searchData() {
      this.fetchData()
    },

    async fetchData() {
      if (!this.branchSelected) return false
      await this.$fetchBranchWithdrawableDay({
        userid: this.branchSelected.userid,
        site: this.selectedSite,
      })
      await this.$fetchBalanceBranchDailyProfit({
        userid: this.branchSelected.userid,
        site: this.selectedSite,
        fromAt: this.periodFrom,
        toAt: this.periodTo,
      })
      this.branchDailyItems = this.fetchBalanceBranchDailyProfit
    },
    async fncFetchBalanceDepositExchange(data) {
      // await this.$fetchBalanceDepositExchange({
      //   site: this.selectedSite,
      //   branch: data.item.branch,
      //   fromAt: data.item.baseDate,
      //   toAt: data.item.baseDate,
      // })
      // this.btnModal('balanceDepositExchange')
    },
    async fncFetchBalanceRolling(data) {
      await this.$fetchBalanceRolling({
        site: this.selectedSite,
        branch: data.item.branch,
        fromAt: data.item.baseDate,
        toAt: data.item.baseDate,
      })
      this.btnModal('balanceRolling')
    },

    /* [formatter] */
    formatNumberWithCommas(value) {
      if (value == null || Number.isNaN(Number(value))) {
        return value
      }

      return parseInt(value, 10).toLocaleString() // 정수로 변환 후 천 단위 쉼표 추가
    },
    getTotalBalance(data) {
      const deposit = Number(data.item.deposit) || 0
      const lowerDeposit = Number(data.item.lowerDeposit) || 0
      const exchange = Number(data.item.exchange) || 0
      const lowerExchange = Number(data.item.lowerExchange) || 0

      // 계산된 잔액
      const total = (deposit + lowerDeposit) - (exchange + lowerExchange)

      // isActive: 4개 항목 중 하나라도 0 이상이면 true, 모두 0이면 false
      const isActive = deposit > 0 || lowerDeposit > 0 || exchange > 0 || lowerExchange > 0

      return {
        total,
        isActive,
      }
    },
    getTotalRollingAmount(data) {
      return (
        Number(data.item.totalSportRollingAmount)
        + Number(data.item.totalCasinoRollingAmount)
        + Number(data.item.totalSlotRollingAmount)
      )
    },
    getDateWithDash(value) {
      if (value == null) {
        return ''
      }
      return moment(value, 'YYYYMMDDHHmmss').format(fmt3)
    },
    getTotal(field) {
      return this.branchDailyItems.reduce((acc, item) => acc + item[field], 0)
    },
    getLast(field) {
      return this.branchDailyItems.reduce((acc, item) => item[field], 0)
    },
    async btnConfirm(type) {
      if (type !== 'BranchCashOut') return false

      if (this.cashOutBranchAmountValidateState) {
        await this.fncShowSwal(
          'confirm',
          `총판 출금 금액( ${Number(this.cashOutBranchAmount).toLocaleString()}원)을 신청하시겠습니까?`,
        )
          .then(result => {
            if (result.isConfirmed) {
              try {
                this.$createBranchCashoutRequest({
                  site: this.selectedSite,
                  requestCash: Number(this.cashOutBranchAmount),
                  requsetMemo: this.cashOutBranchComment,
                })
              } catch {
                console.error()
              } finally {
                this.fncShowSwal('success', '출금 신청 완료되었습니다.')
                this.fetchData()
                this.btnModal('close')
              }
            }
          })
      }
    },
    btnModal(target) {
      switch (target) {
        case 'balanceRolling':
          this.$refs.BranchBalanceRolling.$bvModal.show('BranchCalculateDailyBalanceRolling')
          break
        case 'withdraw':
          if (this.isBranchWithrawable) {
            // this.$refs.ModalBranchCashOut.show()
            this.$refs.BranchCashOutRequest.$bvModal.show('BranchCashOutRequest')
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: '출금가능시간이 아닙니다.',
                icon: 'CheckIcon',
                text: `${this.msgBranchWithrawable}`,
                variant: 'Prmiary',
              },
            },
            {
              position: 'top-center',
              // timeout: 8000,
            })
          }
          break
        case 'withdrawHistory':
          this.$refs.BranchCashOutBalance.$bvModal.show('BranchCashOutBalance')
          break
        default:
          break
      }
    },
    // rowClass(item, type) {
    //   // baseDate 값에 따라 다른 클래스를 반환
    //   if (type === 'row') {
    //     const day = parseInt(item.baseDate.slice(-2), 10)
    //     // console.log(day % 2)
    //     return day % 2 === 0 ? 'table-dark' : 'table-bright'
    //   }
    // },
    rowStyle(item, type) {
      // baseDate 값에 따라 다른 클래스를 반환
      if (type === 'row') {
        const day = parseInt(item.baseDate.slice(-2), 10)
        console.log(day % 2)
        return {
          style: day % 2 === 0
            ? 'background-color: #272a30;' // table-dark 스타일
            : 'background-color: #14161a;', // table-bright 스타일
        }
      }
    },

    /* 출금신청 버튼 활성화 로직 */
    isActiveBranchWithrawable() {
      const isValidCronExpression = cron => {
        if (!cron) return false

        // 현재 시간, 날짜, 요일
        const now = new Date()
        const [currentHour, currentMinute, today, currentDayOfWeek] = [now.getHours(), now.getMinutes(), now.getDate(), now.getDay()]
        const [minutes, hours, dayOfMonth, , dayOfWeek] = cron.split(' ')

        // 조건 체크
        const isTodayValid = dayOfMonth === '*' || today === Number(dayOfMonth)
        const isWeekdayValid = dayOfWeek === '*' || dayOfWeek.split(',').map(Number).includes(currentDayOfWeek)
        const isTimeValid = currentHour > Number(hours) || (currentHour === Number(hours) && currentMinute >= Number(minutes))

        // 출금 가능 여부
        return isTodayValid && isWeekdayValid && isTimeValid
      }

      const cronExpressionMessage = cron => {
        const [minutes, hours, dayOfMonth, , dayOfWeek] = cron.split(' ')
        const dayNames = ['일요일', '월요일', '화요일', '수요일', '목요일', '금요일', '토요일']

        if (dayOfWeek !== '*') {
          return `매주 ${dayNames[dayOfWeek]} ${hours}시 ${minutes}분에 활성화됩니다.`
        } if (dayOfMonth === 'L') {
          return `매월 말일 ${hours}시 ${minutes}분에 활성화됩니다.`
        } if (dayOfMonth !== '*') {
          return `매월 ${dayOfMonth}일 ${hours}시 ${minutes}분에 활성화됩니다.`
        }
        return `매일 ${hours}시 ${minutes}분에 활성화됩니다.`
      }

      this.isBranchWithrawable = isValidCronExpression(this.fetchBranchWithdrawableDay)
      this.msgBranchWithrawable = cronExpressionMessage(this.fetchBranchWithdrawableDay)
      console.log('총판 출금가능: ', this.fetchBranchWithdrawableDay, this.isBranchWithrawable, this.msgBranchWithrawable)
    },

    // 출금 요청 금액 validate
    fncCashOutBranchAmountValidate(newVal, oldVal) {
      // 초기화
      this.cashOutBranchAmountValidateState = null
      this.cashOutBranchAmountValidateMessage = ''
      // 유효성 검사
      if (typeof newVal === 'number') {
        this.cashOutBranchAmountValidateState = false
        this.cashOutBranchAmountValidateMessage = '숫자를 입력바랍니다.'
        this.cashOutBranchAmount = null
      } else if (newVal < 10000) {
        this.cashOutBranchAmountValidateState = false
        this.cashOutBranchAmountValidateMessage = '출금 요청 최소금액은 10,000원 이상입니다.'
      } else if (newVal > this.cashOutBranchAvailable) {
        this.cashOutBranchAmountValidateState = false
        this.cashOutBranchAmountValidateMessage = '출금 요청 금액이 출금 가능 금액을 초과합니다.'
      } else {
        this.cashOutBranchAmountValidateState = true
        this.cashOutBranchAmountValidateMessage = ''
      }
    },

    // Swal
    fncShowSwal(type, title = null, text = null) {
      const config = {
        confirm: {
          title: '변경내용을 저장하시겠습니까?',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Yes',
          background: 'rgb(3,11,37)',
          customClass: {
            cancelButton: 'btn btn-dark',
            confirmButton: 'btn btn-primary mr-1',
          },
        },
        check: {
          title: '변경된 내용이 없습니다. \n 다시 확인바랍니다.',
          icon: 'warning',
          confirmButtonColor: '#3085d6',
          confirmButtonText: 'Yes',
          background: 'rgb(3,11,37)',
          customClass: {
            confirmButton: 'btn btn-primary',
          },
        },
        cancel: {
          title: '변경된 내용이 저장되지 않습니다. 정말 나가시겠습니까?',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Yes',
          background: 'rgb(3,11,37)',
          customClass: {
            cancelButton: 'btn btn-dark',
            confirmButton: 'btn btn-primary mr-1',
          },
        },
        success: {
          title: '저장 완료',
          icon: 'success',
          timer: 5000,
          background: 'rgb(3,11,37)',
          customClass: {
            confirmButton: 'btn btn-primary',
          },
        },
        error: {
          title: '저장 실패',
          icon: 'error',
          text: '시스템 관리자에게 문의바랍니다.',
          timer: 5000,
          background: 'rgb(3,11,37)',
          customClass: {
            confirmButton: 'btn btn-primary',
          },
        },
      }[type]

      // 추가적으로 전달된 title 있다면 해당 설정을 덮어씁니다.
      if (title) config.title = title

      // 추가적으로 전달된 text 있다면 해당 설정을 덮어씁니다.
      if (text === null) delete config.text

      // Swal을 실행합니다.
      return Swal.fire(config)
    },

    // beforeRouteLeave(to, from, next) {
    //   clearInterval(this.fncShowWdrw) // 페이지 이동 시 interval 정리
    //   next()
    // },
  },
}

</script>

<style scoped>
.per-page-selector {
  width: 90px;
}
.category-select {
  width: 80px;
}
/* .b-table {
    overflow-y: auto;
    max-height: 300px;
}
.b-table .table > thead {
  position: sticky !important;
  top: 0;
  z-index: 2;
} */

.b-table > thead > tr > th {
  border-right: solid 1.5px #3b4253 !important;
  border-bottom: solid 1.5px #3b4253 !important;
  min-width: 6rem;
  /* white-space: no-wrap !important; */
}
.b-table > thead > tr > th:last-child {
  border-right: none !important;
}
</style>
